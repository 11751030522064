.select2-container {
  display: block;
}

.select2-container :focus {
  outline: 0;
}

.input-group .select2-container--bootstrap4 {
  flex-grow: 1;
}

.input-group-prepend ~ .select2-container--bootstrap4 .select2-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .select2-container--bootstrap4:not(:last-child) .select2-selection {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--bootstrap4 .select2-selection {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  width: 100%;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .select2-container--bootstrap4 .select2-selection {
    transition: none;
  }
}

.select2-container--bootstrap4.select2-container--focus .select2-selection {
  border-color: #80bdff;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.select2-container--bootstrap4.select2-container--focus.select2-container--open .select2-selection {
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--bootstrap4.select2-container--open.select2-container--above .select2-selection {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--bootstrap4.select2-container--open.select2-container--below .select2-selection {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--bootstrap4.select2-container--disabled .select2-selection, .select2-container--bootstrap4.select2-container--disabled.select2-container--focus .select2-selection {
  cursor: not-allowed;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #e9ecef;
  border-color: #ced4da;
}

.select2-container--bootstrap4.select2-container--disabled .select2-search__field, .select2-container--bootstrap4.select2-container--disabled.select2-container--focus .select2-search__field {
  background-color: #0000;
}

select.is-invalid ~ .select2-container--bootstrap4 .select2-selection, form.was-validated select:invalid ~ .select2-container--bootstrap4 .select2-selection {
  border-color: #dc3545;
}

select.is-valid ~ .select2-container--bootstrap4 .select2-selection, form.was-validated select:valid ~ .select2-container--bootstrap4 .select2-selection {
  border-color: #28a745;
}

.select2-container--bootstrap4 .select2-search {
  width: 100%;
}

.select2-container--bootstrap4 .select2-dropdown {
  border-color: #ced4da;
  border-radius: 0;
}

.select2-container--bootstrap4 .select2-dropdown.select2-dropdown--below {
  border-top: none;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.select2-container--bootstrap4 .select2-dropdown.select2-dropdown--above {
  border-top: 1px solid #ced4da;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.select2-container--bootstrap4 .select2-dropdown .select2-results__option[aria-selected="true"] {
  color: #212529;
  background-color: #f2f2f2;
}

.select2-container--bootstrap4 .select2-results__option--highlighted, .select2-container--bootstrap4 .select2-results__option--highlighted.select2-results__option[aria-selected="true"] {
  color: #fff;
  background-color: #007bff;
}

.select2-container--bootstrap4 .select2-results__option[role="group"] {
  padding: 0;
}

.select2-container--bootstrap4 .select2-results__option[role="group"] .select2-results__options--nested .select2-results__option {
  padding-left: 1em;
}

.select2-container--bootstrap4 .select2-results__option {
  padding: .375rem .75rem;
}

.select2-container--bootstrap4 .select2-results > .select2-results__options {
  max-height: 15em;
  overflow-y: auto;
}

.select2-container--bootstrap4 .select2-results__group {
  color: #6c757d;
  padding: 6px;
  display: list-item;
}

.select2-container--bootstrap4 .select2-selection__clear {
  float: right;
  color: #f8f9fa;
  background-color: #c8c8c8;
  border-radius: 100%;
  width: .9em;
  height: .9em;
  margin-top: .7em;
  margin-right: .3em;
  padding-left: .15em;
  line-height: .75em;
}

.select2-container--bootstrap4 .select2-selection__clear:hover {
  background-color: #afafaf;
}

.select2-container--bootstrap4 .select2-selection--single {
  height: calc(1.5em + .75rem + 2px) !important;
}

.select2-container--bootstrap4 .select2-selection--single .select2-selection__placeholder {
  color: #6c757d;
  line-height: calc(1.5em + .75rem);
}

.select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow {
  width: 20px;
  position: absolute;
  top: 50%;
  right: 3px;
}

.select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow b {
  border: 4px solid #0000;
  border-top: 5px solid #343a40;
  border-bottom-width: 0;
  width: 0;
  height: 0;
  margin-top: -2px;
  margin-left: -4px;
  position: absolute;
  top: 60%;
  left: 50%;
}

.select2-container--bootstrap4 .select2-selection--single .select2-selection__rendered {
  color: #495057;
  padding-left: .75rem;
  line-height: calc(1.5em + .75rem);
}

.select2-search--dropdown .select2-search__field {
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
}

.select2-results__message {
  color: #6c757d;
}

.select2-container--bootstrap4 .select2-selection--multiple {
  min-height: calc(1.5em + .75rem + 2px) !important;
}

.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0 .375rem;
  list-style: none;
}

.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice {
  float: left;
  color: #495057;
  cursor: pointer;
  border: 1px solid #bdc6d0;
  border-radius: .2rem;
  margin-top: calc(.375rem - 2px);
  margin-right: .375rem;
  padding: 0 .75rem 0 0;
}

.select2-container--bootstrap4 .select2-selection--multiple .select2-search__field {
  color: #495057;
}

.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice + .select2-search {
  width: 0;
}

.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice__remove {
  float: left;
  color: #bdc6d0;
  margin-left: 3px;
  margin-right: 1px;
  padding-left: 3px;
  padding-right: 3px;
  font-weight: 700;
}

.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #343a40;
}

.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__clear {
  float: none;
  margin-right: 0;
  top: 0;
  right: .7em;
  position: absolute !important;
}

.select2-container--bootstrap4.select2-container--disabled .select2-selection--multiple .select2-selection__choice {
  cursor: not-allowed;
  padding: 0 5px;
}

.select2-container--bootstrap4.select2-container--disabled .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  display: none;
}

/*# sourceMappingURL=index.c5e8c97f.css.map */
